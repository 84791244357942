@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebarMenuButton {
  @apply p-2 text-left rounded-lg w-5/6 h-12 hover:bg-orange-50 text-gray-400 hover:text-orange-500;
}

.sidebarMenuButtonActive {
  @apply p-2 text-left rounded-lg w-5/6 h-12 bg-orange-50 text-orange-500;
}

.cardPercentage {
  @apply px-2 py-2 rounded-full text-xs flex flex-row items-center gap-1;
}

.cardPercentageIncrease {
  @apply bg-orange-100 text-orange-600;
}

.cardPercentageDecrease {
  @apply bg-red-100 text-red-600;
}

.dataModeButton {
  @apply  text-gray-500 px-4 py-2 rounded-md;
}

.dataModeButtonActive {
  @apply bg-orange-100 text-orange-500;
}